/* eslint-disable import/prefer-default-export */
export function authHeader() {
  let store = JSON.parse(localStorage.getItem('persist:extranet'));
  let login = JSON.parse(store.login);
  const { user } = login;
  if (store && user.token) {
    return { Authorization: `Bearer ${user.token}`, 'Content-Type': 'application/json' };
  } else {
    return {};
  }
}
